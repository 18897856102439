import React, { FC, ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';

import { useStores } from '../../hooks';
import { scoredChoice, scoredQuestion, scoredPage } from '../../types';
import { useTranslation } from 'react-i18next';

const ResultsPageContainer = styled.div`
  visibility: visible;
  display: block;
  border: 2px black solid;
  margin: 50px;
  padding: 40px;
`;

const ScoredPageContainer = styled.div`
  padding-top: 5vh;
`;

const QuestionContainer = styled.div`
  text-indent: 2em;
  padding-bottom: 1vh;
`;

const PdfButton = styled.button``;

const ButtonContainer = styled.span`
  @media print {
    ${PdfButton} {
      display: none;
    }
  }
`;

interface TaskParagraphProps {
    choice: scoredChoice
    bold?: boolean
}

const TaskParagraph = ({choice, bold}: TaskParagraphProps) => {
  const { t } = useTranslation();
  return (
    <p>
      {
        bold ?
          <b>{choice.choiceName}</b> : choice.choiceName
      }
      {choice.score ? ` ${t('pages.admin.resultsPage.correctAnswer')}` : ''}
    </p>
  );
};

const ResultsPage: FC = observer((): ReactElement => {
  const { t } = useTranslation();

  const { adminStore } = useStores();

  const removeUnwantedTags = (question: string) => {
    return question.replaceAll('<br>', ' ').replaceAll('<strong>', '').replaceAll('</strong>', '');
  };

  useEffect(() => {
    adminStore?.fetchIndividualScores();
  }, []);

  const response = adminStore?.individualScores;
  const IsNotAnswered = (scoredQuestion: scoredQuestion) => {
    if(!scoredQuestion.scoredChoices && !scoredQuestion.answer)
    {
      return true;
    }

    if(scoredQuestion.scoredChoices && scoredQuestion.scoredChoices.every(choice => choice.selected === false))
    {
      return true;
    }

    return false;
  };

  if (response && response.scoredExaminee !== null) {
    const { firstName, lastName, email, totalScore, scoredPages } = response.scoredExaminee;

    return (
      <ResultsPageContainer>
        <ButtonContainer>
          <PdfButton onClick={() => window.print()}>{t('pages.admin.resultsPage.savePDF')}</PdfButton>
        </ButtonContainer>
        <h1>{response.name}</h1>
        <p>
          {t('pages.admin.resultsPage.examineeName', {
            firstName: firstName,
            lastName: lastName,
          })}
        </p>
        <p>
          {t('pages.admin.resultsPage.email') + ': '}
          <a href={'mailto:' + email} style={{ color: 'hotpink' }}>
            {email}
          </a>
        </p>
        <p>
          {t('pages.admin.resultsPage.totalScore', {
            totalScore: totalScore,
          })}
        </p>
        <p
          dangerouslySetInnerHTML={{
            __html: t('pages.admin.resultsPage.info', { interpolation: { escapeValue: false } }) 
            + ' "' + t('pages.admin.resultsPage.notAnswered') + '"<br></br>' + 
            t('pages.admin.resultsPage.correctAnswers'),
          }}
        />
        {scoredPages.map((scoredPage: scoredPage, i: number) => (
          <ScoredPageContainer key={i}>
            <h3 key={i}>{scoredPage.page}</h3>
            {scoredPage.scoredQuestions.map((scoredQuestion: scoredQuestion, idx: number) => {
              if(scoredQuestion.answer && typeof scoredQuestion.answer === 'string')
              {
                return (
                  <>
                    <p key={idx}>
                      {scoredQuestion.position} &nbsp;
                      {removeUnwantedTags(scoredQuestion.questionName)}
                    </p>
                    <QuestionContainer>
                      <strong>{scoredQuestion.answer}</strong>
                    </QuestionContainer>
                  </>
                );
              }

              if(IsNotAnswered(scoredQuestion)){
                return (
                  <>
                    <p key={idx}>
                      {scoredQuestion.position} &nbsp;
                      {removeUnwantedTags(scoredQuestion.questionName)}
                    </p>
                    <QuestionContainer>
                      {t('pages.admin.resultsPage.notAnswered')}
                    </QuestionContainer>
                  </>
                );
              }

              return (
                <>
                  <p key={idx}>
                    {scoredQuestion.position} &nbsp;
                    {removeUnwantedTags(scoredQuestion.questionName)}
                  </p>
                  <QuestionContainer>
                    {scoredQuestion.scoredChoices && 
                      scoredQuestion.scoredChoices.map((choice: scoredChoice, index: number) => {
                        return (
                          <TaskParagraph bold={choice.selected} choice={choice} key={index}/>
                        );
                      })}
                  </QuestionContainer>
                </>
              );
            })}
          </ScoredPageContainer>
        ))}
      </ResultsPageContainer>
    );
  } else return <ScoredPageContainer>NO DATA</ScoredPageContainer>;
});

export default ResultsPage;
